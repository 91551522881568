import { defineStore, storeToRefs } from 'pinia'
import type { Undefinable } from 'ts-helpers'
import { computed, ref } from 'vue'
import { useConfiguration } from '@/5_entities/Configuration'
import { useUser } from '@/5_entities/User'
import type { Location } from './type'

export const useLocation = defineStore('location', () => {
  const { locations } = storeToRefs(useConfiguration())
  const { updateUserLocation } = useUser()

  const currentLocation = ref<Undefinable<Location>>(undefined)
  const locationIsNotDefined = ref(false)

  const setLocationOnInit = (location?: Location, adjustLocation?: boolean) => {
    if (!location) {
      const defaultLocation: Location = locations.value?.find((item) => item.name === 'Москва') ?? {
        id: -1,
        name: 'Москва',
        displayName: 'Москва',
        isPinned: true
      }

      setLocation(defaultLocation, true)

      return
    }

    setLocation(location, !!adjustLocation)
  }

  const setLocation = (location: Location, adjustLocation: boolean = false) => {
    const needToUpdatePage =
      (currentLocation.value?.id && location.id !== currentLocation.value.id) ||
      locationIsNotDefined.value

    currentLocation.value = location
    locationIsNotDefined.value = adjustLocation

    needToUpdatePage && updateUserLocation(location, needToUpdatePage)
  }

  const getLocationsByChar = (locations: Location[]) => {
    const collator = new Intl.Collator()

    return [...locations]
      .sort(({ name: nameA }, { name: nameB }) => {
        return collator.compare(nameA.toUpperCase(), nameB.toUpperCase())
      })
      .reduce(
        (acc, item) => {
          const group = item.name[0].toUpperCase()

          acc[group] = acc[group] ?? []
          acc[group].push(item)

          return acc
        },
        {} as Record<string, Location[]>
      )
  }

  const pinnedLocations = computed(() => {
    if (!locations.value) return []

    const defaultList = locations.value.filter((item) => item.isPinned)

    if (
      currentLocation.value &&
      !defaultList.find((item) => Number(item.id) === Number(currentLocation.value!.id))
    ) {
      defaultList.push(currentLocation.value)
    }

    defaultList.forEach((item, index) => {
      if (item.id === currentLocation.value?.id) {
        defaultList.splice(index, 1)
        defaultList.unshift(item)
      }
    })

    return defaultList
  })

  return {
    currentLocation,
    locationIsNotDefined,
    setLocation,
    pinnedLocations,
    locations,
    setLocationOnInit,
    getLocationsByChar
  }
})
