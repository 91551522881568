import { createRouter, createWebHistory } from 'vue-router'
import { afterEach, beforeEach } from './guards'
import { routes } from './routes'

export const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve) => {
      setTimeout(() => {
        if (savedPosition) {
          resolve(savedPosition)
        }

        if (to.name !== from.name) {
          resolve({ top: 0 })
        }
      })
    })
  }
})

router.beforeEach(beforeEach)
router.afterEach(afterEach)
